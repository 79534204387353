.visitor-history {
  .MuiAutocomplete-root,
  .MuiAutocomplete-root .MuiInputBase-input {
    font-size: 14px !important;
    
  }
  
}
.MuiAutocomplete-listbox{
    font-size: 14px !important;
}

.historyTable .MuiTablePagination-root
{
  left: 0;
  position: absolute;
}