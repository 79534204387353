@import "../../styles/mixin/_breakpoints.scss";

.people-card {
  @include for-tablet-landscape-down {
    margin: 100px 30px;
  }
  @include for-tablet-landscape-up {
    margin: 100px 30px;
  }
  .people-card-paper{
    @include for-tablet-landscape-down {
        width: 90%;
      }
      @include for-tablet-landscape-up {
        width: 60%;
      }
  }

  .statusBtn.checkIn
  {
    background: #3d6723;
    color: #fff;
    font-size: 20px;
  }

  .statusBtn.checkOut
  {
    background: #c14621;
    color: #fff;
    font-size: 20px;
  }
}
