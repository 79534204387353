.visitors {
  .visitor-metrics-icon {
    width: 24px;
    height: 24px;
  }
  .tabpanel {
    padding-top: 8px;
    .MuiBox-root {
      padding: 0px;
    }
  }
}

.addBtn
{
  right: 8px;
  position: absolute !important;
  z-index: 999;
}
