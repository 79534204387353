

.login-container{
    .google-login span{
        font-size: 18px;
        font-family:'Ubuntu', sans-serif;
    }
    .google-login iframe{
        margin: 0 auto !important;
    }
}