.dashboard {
  padding-top: 50px;
  .icon-container {
    width: 90px;
    height: 90px;
    background-color: #fff;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      fill: #141415 !important;
      font-size: 2rem;
      opacity: 0.7;
    }
  }
  .icon {
    width: 40px;
    height: 40px;
  }
  .nav-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    .nav-item {
      flex-grow: 1;
      padding-top: 30px;
      width: 30%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 10px;
      .nav-title{
        padding-top: 30px;
        font-size: 14px;
      }
    }
  }
}
