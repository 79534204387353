.label-item {
  div {
    margin-top: 12px;
    color:#888888;
  }
  label {
   // font-weight: bold;
  }
  font-size: 14px;
}
