body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Ubuntu", sans-serif;
}

.logo-svg path.st0 {
  fill: #2e3192;
}
.MuiTypography-root.MuiFormControlLabel-label,
.MuiInputBase-input,
.MuiFormLabel-root {
  font-size: 14px !important;
  line-height: 1.5rem !important;
}

.MuiTable-root th{

  font-size: 14px;
}
.MuiTable-root td{
  color:#888888;
  font-size: 14px;
}

.cardUser .card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cardUser .card-img-top {
  height: 200px;
  object-fit: cover;
}

.instructions { line-height: 2; }

.instructions li { border-top: 1px dashed #28b6d5;}

.agreeBtn {
  background: #2ba265 !important;
  color: #fff !important;
  width: 300px !important;
  font-size: 30px !important;
  margin: 0px auto !important;
  display: block !important;
}

.attForm .form-group { margin-bottom: 15px; }

.attForm label { font-weight: bold; }

form.attForm {
  margin-top: 2%;
  border-top: 2px dotted #932626;
  padding-top: 15px;
}

#printOnly {
  display : none;
}

#hideBtn { display: block; }

div#printOnly img {
  width: 35%;
  margin: 0px auto;
  display: block;
  margin-bottom: 30px;
}

#noPrint { display: block; }

@media print {
   #printOnly {
      display : block;
   }

   #hideBtn { display: none; }

   #noPrint { display: none; }
}

/*.blinking-border {
  background: #0080001a;
  padding: 20px;
  border: 5px solid #50bd47;
  animation: blink 1s 3;
}

.blinking-border-out {
  background: #d25b3d1a;
  padding: 20px;
  border: 5px solid #ea7054;
  animation: blinkout 1s 3;
}

@keyframes blink {
  0%, 100% { border-color: green; }
  50% { border-color: transparent; }
}

@keyframes blinkout {
  0%, 100% { border-color: #ea7054; }
  50% { border-color: transparent; }
}*/

.badgeBox
{
  right: 10%;
    position: absolute;
    background: #53b04c;
    color: #fff;
    padding: 5px 20px;
}

.badgeBoxOut
{
  right: 10%;
    position: absolute;
    background: #ea7054;
    color: #fff;
    padding: 5px 20px;
}

.testClass .MuiPaper-root { max-width: none; width: 50% !important; }

.listDecals {
  margin-top: 20px;
}

.itemDecal {
  margin-right: 10px !important;
}

@keyframes slideOut {
  from {
      opacity: 1;
      transform: translateX(0);
  }
  to {
      opacity: 0;
      transform: translateX(100%);
  }
}

.slide-out {
  animation: slideOut 0.5s forwards;
}

.MuiDataGrid-cell--textLeft
{
  font-weight: bold;
  font-size: 18px;
}
