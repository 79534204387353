@import "../../styles/mixin/_breakpoints.scss";

.visitor.card-container {
  @include for-tablet-landscape-down {
    padding-top: 20px;
    width: 80%;
    margin: 0 auto;
  }
  @include for-tablet-landscape-up {
    width: 50%;
    margin: 0 auto;
  }
}

.time-picker{
  .MuiStack-root{
    padding: 0px;
  }
}

img.loaderIcon {
  width: 10%;
  margin: 0px auto;
  display: block;
}

.hide_loader { display: none !important; }
.show_loader { display: block !important; }

.upload-button {
  border: 1px solid #ccc;
  padding: 14px;
  border-radius: 4px;
  width: 91%;
}

.css-1emevws
{
  background-color: #1a3b61 !important;
}

.css-157b63h-MuiTypography-root
{
  color: #fff !important;
}

.css-1c9fpx3-MuiAvatar-root
{
  width: 50% !important;
  height: 100% !important;
}

.MuiInputBase-input
{
  font-size: 16px !important;
    line-height: 1.5rem !important;
    font-weight: 600 !important;
    color: #972626 !important;
}